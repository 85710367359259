import React from "react";

const Spinner = (props) => {
  const { type } = props;
  return (
    <div>
      {type === "letters" && (
        <div className="loader">
          <span>{"#"}</span>
          <span>l</span>
          <span>o</span>
          <span>a</span>
          <span>d</span>
          <span>i</span>
          <span>n</span>
          <span>g</span>
        </div>
      )}
      {type === "dots" && (
        <div className="loader">
          <span>{"*"}</span>
          <span>{"*"}</span>
          <span>{"*"}</span>
          <span>{"*"}</span>
        </div>
      )}
    </div>
  );
};

export default Spinner;
